footer {
	background-color: var(--primary);
	padding: 5px 0px;
	color: var(--white);

	.footer_top {
		background-color: var(--secondary);
		border-top: 5px solid var(--white);
		border-bottom: 5px solid var(--white);
		padding: 30px;
		color: var(--white);

		@media (max-width:992px) {
			padding: 30px 10px;
		}

		a {
			color: inherit;

		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				padding: 3px 0;

				a {
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.footer-logo {
			font-family: verdana, sans-serif;
			font-size: 28px;
			font-weight: bold;
			padding-bottom: 20px;
		}

		p {
			color: inherit;
		}

		.widget-title {
			font-size: 20px;
			font-weight: bold;

			@media (max-width:992px) {
				margin-top: 15px;
			}
		}
	}

	.footer_social_icon {

		@media (max-width:992px) {
			margin-top: 15px;

			position: absolute;
			right: 20px;

		}

		a {
			font-size: 36px;
			border: 3px solid var(--white);
			width: 49px;
			height: 49px;
			display: inline-block;
			text-align: center;
			line-height: 49px;
			border-radius: 5px;


			&:hover {
				text-decoration: none;
			}
		}
	}

	.footer_bottom {
		span {
			font-size: 12px;
			color: var(--white);
			padding: 6px 0;
			display: inline-block;

		}

		.col-sm-6 {
			@media (max-width:767px) {
				text-align: center;
			}
		}

		.links {
			margin: 10px 0;


		}
	}

	a {
		color: var(--white);

		&:hover {
			text-decoration: underline;
			color: var(--white);
		}
	}

	.flex-end {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		flex-wrap: wrap;

		@media (max-width:992px) {
			position: static;
		}
	}
}