@font-face {
	font-family: 'Open Sans';
	src: url('RESOURCE/fonts/OpenSans-SemiBold.eot');
	src: url('RESOURCE/fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/OpenSans-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/OpenSans-SemiBold.ttf') format('truetype'),
		url('RESOURCE/fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('RESOURCE/fonts/OpenSans-Regular.eot');
	src: url('RESOURCE/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/OpenSans-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-Regular.woff') format('woff'),
		url('RESOURCE/fonts/OpenSans-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('RESOURCE/fonts/OpenSans-Bold.eot');
	src: url('RESOURCE/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/OpenSans-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-Bold.woff') format('woff'),
		url('RESOURCE/fonts/OpenSans-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}


@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.offer-anniv {
	background-color: #002a5c;
	padding: 5px;
	text-align: center;

	a {
		display: inline-block;
		color: #fff;


		@media (min-width:768px) {
			font-size: 18px;
		}
	}
}

.single-unit {
	border: 1px solid rgba(0, 0, 0, .125);
	margin-bottom: 30px;

	.unit-title {
		color: var(--grey);
		font-weight: bold;
		padding-top: 10px;
	}

	.unit-dt {
		color: var(--grey);
		font-size: 16px;
	}

	.unit-address {
		color: var(--grey);
		font-size: 13px;

		i {
			font-size: 11px;
		}
	}

	.unit-dt-bl {
		display: flex;
		align-items: center;
		justify-content: space-between;

		span {
			background-color: var(--blue-light);
			color: var(--primary);
			width: 100%;
			font-size: 14px;
			display: inline-block;
			text-align: center;
			padding: 5px;

			strong {
				display: block;
				margin-bottom: 8px;
				color: var(--primary);
				font-size: 16px;

			}
		}

		span+span {
			margin-left: 10px;
		}
	}

	.img-content {
		height: 232px;
		position: relative;

		.new-tag {
			width: 39px;
			height: 39px;
			position: absolute;
			right: 10px;
			top: 10px;
			background-image: url(RESOURCE/img/neu.png);

		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.fav-icon {
			position: absolute;
			right: 0;
			bottom: 0;
			padding: 10px 10px 6px;
			background-color: rgba(255, 255, 255, .7);

			i.fa {
				font-size: 20pt;
				color: var(--secondary);
			}
		}
	}

	.search-result-properties {
		display: flex;
		flex-wrap: wrap;

		>div {
			display: inline-block;
			margin-right: 5px;

		}

		i.fi,
		i.fa {
			width: 27px;
			border: 1px solid var(--black);
			height: 27px;
			text-align: center;
			margin-bottom: 5px;
			border-radius: 5px;
			font-size: 20px;
			line-height: 24px;
			color: var(--black);
			border-radius: 5px;
			display: inline-block;
		}

	}

	.unit-content {
		padding: 20px;
	}
}

.next-prev-btns {
	position: relative;

	.owl-nav {
		position: absolute;
		top: 45%;
		left: 0;
		width: 100%;

		button {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			position: absolute;
			left: 20px;

			@media (max-width:992px) {
				left: -6px;
			}

			span {
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				color: transparent;

				&:before {
					color: var(--white);
					font-family: "Font Awesome 5 Free";
					position: relative;
					left: 2px;
					font-size: 24px;
					top: 1px;
				}
			}

			&.owl-next {
				position: absolute;
				right: 20px;
				left: initial;

				@media (max-width:992px) {
					right: -6px;
				}

				span {

					&:before {
						content: "\f054";
						left: 3px;
					}
				}
			}

			&.owl-prev {
				span {

					&:before {
						content: "\f053";

					}
				}
			}
		}
	}
}

.fa {
	&.fa-search {
		transform: rotate(90deg);
	}
}

.flex {
	@mixin flex;
}

.full-img {
	width: 100%;
	max-width: 100%;
}

.mt-15 {
	margin-top: 15px;
}

.mt-2 {
	margin-top: 2em;
}

.pad60 {
	padding: 60px 0 !important;
}

.pt-0 {
	padding-top: 0 !important;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.pb-30 {
	padding-bottom: 30px !important;
}

.fa-heart-o {
	&:before {
		content: "\f15c" !important;
	}

	font-family: federicons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: middle;
}

.m-0 {
	margin: 0;
}

.fa-heart {
	&:before {
		content: "\f15d" !important;
	}

	font-family: federicons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: middle;
}

.asd__wrapper--full-screen {
	padding-top: 140px;
}

.doc-box {
	text-align: left;

	.header {
		h4 {
			color: #000;
			margin-top: 8px;
			font-weight: bold;
		}
	}
}

p {
	line-height: 1.5;
}

@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}

.unit-list-section {
	.three-units-bl {
		display: none;

		&:first-child {
			display: block !important;
		}

		&:nth-child(2),
		&:nth-child(3) {
			display: block !important;
		}
	}
}

.cookie-dlg {
	.header {
		h4 {
			margin: 0;
		}
	}
}

.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}


a {
	color: var(--primary);
}

.btn-go {
	background-color: #007da7;
	color: #FFF !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}

.mb-10 {
	margin-bottom: 10px;
}

.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: #0187B4;
	border: 1px solid #09BDF3;
}

.pb-0 {
	padding-bottom: 0 !important;
}


.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

.f35 {
	font-size: 35px;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.f16 {
	font-size: 16px;
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.table {
	td {
		padding: 15px;
	}
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {
	label {
		font-weight: normal;
		text-align: left !important;
	}

	label.lrequired {
		font-weight: 700;
	}

	margin-bottom: 20px;
}


.payment-view {
	padding-top: 180px;
	padding-bottom: 140px;

	.btn {
		box-shadow: initial;
	}
}

label {
	font-weight: normal;
	font-size: 16px;
}


.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

.asd__day--tada {
	background-color: red;
}

.datepicker-trigger {


	.asd__day--not-available {
		background: var(--not_available) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival {
		background: linear-gradient(135deg, var(--available) 50%, var(--not_available) 50%) !important;
		opacity: 1 !important;
	}

	.asd__day--departure {
		background: linear-gradient(135deg, var(--not_available) 50%, var(--available) 50%) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(135deg, var(--disabled) 50%, var(--not_available) 50%) !important;
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(135deg, var(--not_available) 50%, var(--disabled) 50%) !important;
	}

	.asd__day--disabled.asd__day--in-range {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--enabled.asd__day--in-range {
		background: var(--selection_range) !important;
		color: white !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--enabled.asd__day--hovered {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		color: var(--disabled) !important;
	}

	.asd__day--enabled {
		background-color: var(--available) !important;
		color: var(--text_color) !important;

		/*button {
			text-decoration: underline;
		}*/

		&:hover {
			background-color: var(--selected) !important;
			color: white !important;
		}



		&.asd__day--disabled {
			background-color: var(--disabled) !important;
			color: var(--text_color);
			text-decoration: underline;

			&.asd__day--selected {
				background-color: var(--selected) !important;
			}

			&.asd__day--in-range {
				background: var(--selection_range) !important;
				opacity: 1 !important;
				border: 1px double var(--selected_border) !important;
			}

			&.asd__day--hovered {
				background: var(--selection_range) !important;
				opacity: 1 !important;
				border: 1px double var(--selected_border) !important;
			}
		}

		&.asd__day--arrival {
			&:hover {
				background-color: var(--selected) !important;
			}
		}

		&.asd__day--not-available {
			background: var(--not_available) !important;
			opacity: 1 !important;
		}
	}

	.asd__day--selected {
		background: var(--selected) !important;
		color: white !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;

		/*Arrow display css start*/
		&.asd__selected-date-one {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-left: 12px solid transparent;
				border-top: 19px solid var(--selection_range);
				border-bottom: 20px solid var(--selection_range);
				right: 0px;
			}
		}

		&.asd__selected-date-two {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-right: 12px solid transparent;
				border-top: 19px solid var(--selection_range);
				border-bottom: 20px solid var(--selection_range);
				left: 0px;
			}
		}

		/*Arrow display css end*/
	}


}

.pt-3 {
	padding-top: 30px;
}

.pt-15 {
	padding-top: 15px;
}

/*
.pswp {
	display: none;
}
*/
.f28 {
	font-size: 28px;
}

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

.colored-text {
	color: var(--primary);
}

.checkbox-success input[type=checkbox]:checked+label:before {
	background-color: var(--primary);
	border-color: var(--primary);
}

.region-view {
	min-height: 750px;
}

a,
input,
button,
.btn,
*,
a:focus,
input:focus,
button:focus,
.btn:focus,
.checkbox input[type=checkbox]:focus+label:before {
	outline: none !important;
	outline-offset: 0 !important;
}

.pagination-lg>li>a {
	font-size: 14px;
	border: solid 1px #666666 !important;
	background-color: #E4E2E2;
	color: #666666;
	text-decoration: none;
	margin: 2px;
	padding: 3px 8px !important;
}

.pagination-lg>li>a:hover {
	background-color: #fff !important;
}



.pagination>.active>a {
	border: solid 1px #316BA0;
	background-color: #E67D3C;
	color: white;
}

.pagination>.disabled>a {
	border: none !important;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
	background-color: var(--primary);
	border-color: var(--primary);
	color: var(--white) !Important;
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}



.asd__mobile-close {
	background-color: var(--black);
	color: var(--white);
	width: 36px;
	border-radius: 5px;
	margin-right: 10px;
}

#panorama {
	width: 100%;
	height: 400px;

	@media (max-width:550px) {
		height: 320px;
	}
}

.form-control {
	&::-webkit-input-placeholder {
		/* Edge */
		color: #868e96;
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #868e96;
	}

	&::placeholder {
		color: #868e96;
	}


	padding:10px 12px;
	height: auto;
	border-radius:0;
}



.dropdown-backdrop {
	display: none !important;
}

.guestbox {
	/*	margin-top: 45px !important;*/
}

blockquote {
	border-left-color: var(--primary);
}

.popover {
	.popover-content {
		ul {
			padding: 0;
			list-style: inside;
		}
	}
}

.pad30 {
	padding: 30px 0;
}

a {

	&:hover,
	&:focus {
		text-decoration: none;
	}

	&.blue-hover {
		color: var(--primary);

		&:hover {
			color: var(--primary);
		}
	}

}

.white-cont-bl {
	padding: 20px;
	background-color: var(--white);

	@media (min-width:993px) {
		margin-left: -20px;
	}
}

.mb-0 {
	margin-bottom: 0;
}

html,
body {
	font-family: 16px;
	color: var(--grey);
	font-family: "Open Sans", Arial, serif;
}

.btn {
	border-radius: 0;
	font-size: 16px;
	padding: 9px 10px 6px;

	@media (max-width:992px) {
		font-size: 14px;
		padding: 6px 10px;
	}

	&.btn-primary,
	&.btn-go,
	&.btn-warning {
		background-color: var(--primary);
		color: var(--white);
		border-color: var(--primary);

		&:hover {
			background-color: var(--secondary);
			border-color: var(--secondary);
		}

		&.btn-sm {
			font-size: 14px;
		}
	}

	&.btn-default {
		color: var(--black);

		&.btn-sm {
			font-size: 14px;
		}

		&:hover {
			background-color: var(--primary);
			color: var(--white);
		}
	}

	&.btn-sm {
		font-size: 14px;
		padding: 6px 12px;
	}

	&.btn-secondary {
		background-color: var(--secondary);
		color: var(--white);
		border-color: var(--secondary);

		&:hover {
			background-color: var(--primary);
			border-color: var(--primary);
			color: var(--white);

		}

		&.style2 {
			&:hover {
				background-color: #e55e00;
				border-color: #db5900;
			}
		}
	}

	&.btn-lg {
		height: 46px;
		font-size: 18px;
		padding: 10px 16px;
	}

	&.big-pad {
		padding: 8px 30px;
	}

	&:focus {
		outline: 0 !important;
		text-decoration: none !important;
	}
}

.f27 {
	font-size: 27px;
}

.f19 {
	font-size: 19px;
}

.pb-20 {
	padding-bottom: 20px;
}

.mb-20 {
	margin-bottom: 20px;
}

.text-blue {
	color: var(--primary);
}


p {
	color: var(--grey);
	font-size: 16px;
}

.bold {
	font-weight: bold;
}

.text-secondary {
	color: var(--secondary);

}

.text-primary {
	color: var(--primary);
}

.text-grey {
	color: var(--grey);
}

.bg-blue-light {
	background-color: var(--blue-light) !important;

	@media (max-width:992px) {
		&.bg-mob-white {
			background-color: var(--white) !important;
		}
	}
}

.show-mob {
	display: none;

	@media (max-width:767px) {
		display: block;
	}
}

.hide-mob {
	display: block;

	@media (max-width:767px) {
		display: none;
	}
}

.mt-0 {
	margin-top: 0 !important;
}

.flex-row {
	display: flex;
	flex-wrap: wrap;
}

.bg-light-orange {
	background-color: var(--orange-light) !important;
}

.link-secondary {
	color: var(--secondary);

	&:hover {
		color: var(--primary);
		text-decoration: underline;
	}
}

.flex-row-reverse {
	display: flex;
	flex-direction: row-reverse;
	flex-wrap: wrap;
}

.font-dancing {
	font-family: Dancing Script, cursive;
}

@font-face {
	font-family: 'federiconsregular';
	src:
		url('RESOURCE/fonts/federicons.eot?#iefix"') format('embedded-opentype'),
		url('RESOURCE/fonts/federicons-webfont.woff2') format('woff2'),
		url('RESOURCE/fonts/federicons-webfont.woff') format('woff');

	font-weight: normal;
	font-style: normal;

}

.fi {
	line-height: 1;
}


.fi:before {
	font-family: federiconsregular !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: middle;
}

.fi-dtv:before {
	content: "\f1d1";
}


@font-face {
	font-family: "federicons";
	src: url("RESOURCE/fonts/federicons.eot?cd6cadcae9143dab661ef58dadf1e9cd?#iefix") format("embedded-opentype"), url("RESOURCE/fonts/federicons.woff2?cd6cadcae9143dab661ef58dadf1e9cd") format("woff2"), url("RESOURCE/fonts/federicons.woff?cd6cadcae9143dab661ef58dadf1e9cd") format("woff");
}

.fi {
	line-height: 1;

	&.fi-stso-schirm::before {
		content: "\f1d2";
	}

	.fi-like:before {
		content: "\f15c";
	}

	&.fi-dtv::before {
		content: "\f1d1";
	}

	&.fi-kitchen-oven:before {
		content: "\f11f";
	}

	&.fi-microwave:before {
		content: "\f123";
	}

	&.fi-dishwasher:before {
		content: "\f122";
	}

	&.fi-bycicle:before {
		content: "\f1d3";
	}

	&.fi-patio:before {
		content: "\f132";
	}

	&.fi-balcony:before {
		content: "\f131";
	}

	&.fi-nose:before {
		content: "\f156";
	}

	&.fi-pool:before {
		content: "\f10b";
	}

	&.fi-sauna:before {
		content: "\f10c";
	}

	&.fi-beach-chair:before {
		content: "\f17d";
	}

	&.fi-washing-machine:before {
		content: "\f11a";
	}

	&.fi-car-parking:before {
		content: "\f134";
	}

	&.fi-elevator2:before {
		content: "\f137";
	}

	&.fi-wlan:before {
		content: "\f140";
	}

	&.fi-pets-allowed:before {
		content: "\f14d";
	}

	&.fi-pets-not-allowed:before {
		content: "\f14f";
	}

	&.fi-smoking-not-allowed2:before {
		content: "\f14b";
	}

	&.fi-washing-machine2:before {
		content: "\f11b";
	}

	&.fi-bathtub:before {
		content: "\f102";
	}
}

.fi:before {
	font-family: federicons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: middle;
}

.tooltip-inner {
	background-color: var(--primary) !important;
}

.text-yellow {
	color: #EEB41B !important;
}



.three-col {
	.col-lg-4 {
		@media (min-width:1200px) {
			&:nth-child(3n+4) {
				clear: both;
			}
		}


		@media (max-width:1199px) {
			&:nth-child(2n+3) {
				clear: both;
			}
		}
	}
}

h1,
h2,
h3,
h4,
h5 {
	margin-top: 0;
}

#swipebox-prev,
#swipebox-next,
#swipebox-close {
	background-image: url(RESOURCE/img/icons.png) !imortant;
	background-repeat: no-repeat;
	border: none !important;
	text-decoration: none !important;
	cursor: pointer;
	width: 50px;
	height: 50px;
	top: 0;
}

.datepicker-ext-row {
	display: flex;
	align-items: center;
	margin: 20px 0 0 0 !important;

	@media (max-width: 767px) {
		flex-wrap: wrap;
	}

	span {
		font-size: 15px;

		@media (max-width: 767px) {
			margin: 0 0 15px 0;
			max-width: 90px;
			display: inline-block;
			width: 100%;
		}
	}

	select {
		height: 40px;
		max-width: 130px;
		width: 100%;
		border-radius: 0;
		padding-left: 5px;
		padding-right: 5px;
		margin: 0 20px 0 10px;
		border: 1px solid rgba(0, 0, 0, .2);
		background-position: 96% 16px;

		@media (max-width: 767px) {
			margin: 0 20px 10px 10px;
			max-width: 160px;
		}

		@media all and (min-width: 400px) and (max-width: 480px) {
			max-width: 180px;
		}
	}
}

.datepicker-trigger {
	.asd__wrapper {
		@media (max-width: 480px) {
			margin: 0 auto;
		}
	}
}

.popover {
	max-width: 500px !important;

	a {
		white-space: nowrap;
	}
}

.pad30 {
	padding: 30px;
}

.banner_logo2 {
	@media (max-width:767px) {
		margin-bottom: 20px;
	}
}

.pad-top-btn30 {
	padding-top: 40px;
	padding-bottom: 20px;
}

.banner_logo {
	width: 200px;
	position: absolute;
	bottom: 40px;
	left: 70px;

	@media (max-width: 1200px) {
		left: 40px;
	}

	@media (max-width: 992px) {
		bottom: 20px;
		left: 30px;
		width: 140px;
	}

	@media (max-width: 767px) {
		width: 80px;
		bottom: 10px;
		left: 20px;
	}

	img {
		width: 100%;
	}
}



.vo-index-body {
	.indexpage {
		.iheader {
			&+.offer-anniv {
				display: none;
			}
		}
	}

	.navbar-area {
		&.affix-top {
			.offer-anniv {
				display: none
			}
		}
	}
}