@import "common.css";

.unit-view {
	padding-top: 210px;


	.title-img-container {
		width: 100%;
		cursor: pointer;
	}

	.v-img-gallery {
		margin-top: 20px;
		overflow: hidden;

		.owl-carousel {
			background-color: var(--orange-light);

		}

		.owl-item {
			@media (min-width:993px) {
				padding: 0 15px;
			}
		}


		.item {
			height: 500px;
			width: 100%;
			overflow: hidden;

			@media (max-width:992px) {
				height: 350px;
			}

			@media (min-width:993px) {
				padding: 0 15px;
			}

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}

			span {
				background-color: rgba(0, 0, 0, 0.2);
				padding: 5px 8px;
				position: absolute;
				left: 50%;
				bottom: 20px;
				transform: translate(-50%, 0);
				display: inline-block;
				color: var(--white);
				font-size: 16px;
			}
		}

		.owl-thumbs {
			margin-top: 15px;

			.img-thumbnail {
				padding: 0;
				border: none;
				margin: 4px;
				border-radius: 0 !important;
			}
		}
	}

	.search-section {
		.searchbox {}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}
	}

	.table {
		&.table-striped {
			border-color: #ddd;

			td {
				padding: 15px;
				border-top: 1px solid #ddd;
			}

			.title-bdr {
				border-bottom: 1px dotted #1b1b1b;
				padding-bottom: 8px;
				font-weight: bold;
				padding-left: 8px;
				padding-right: 8px;
			}

			ul {
				padding: 0;
				margin-left: 8px;

				li {
					padding: 0;
					list-style: none;
					width: 33%;
					font-size: 17px;
					display: inline-block;

					@media (max-width:992px) {
						width: 50%;
					}

					@media (max-width:550px) {
						width: 100%;
					}

					&:before {
						content: "\f00c";
						color: var(--secondary);
						font-family: 'FontAwesome';
						margin-right: 8px;
					}

				}
			}
		}

		&.price-table {
			td {
				padding: 4px 15px;
			}
		}
	}

	.table-striped>tbody>tr:nth-of-type(odd) {
		background-color: #EEEAE3;
	}


	.ratings {
		margin-top: 30px;

		.stars {
			.fa {
				color: var(--gold);
			}
		}
	}

	.rightpanel {
		margin-top: 30px;
		margin-bottom: 20px;

		.stars {
			color: var(--gold);
		}

		.unit-ft-li {
			margin-bottom: 15px;
		}

		.fav-section {
			i {
				display: none;
			}
		}

		.side-heading {
			background-color: var(--primary);
			color: var(--white);
			padding: 12px 14px;
			margin-bottom: 0;
			font-size: 16px;
			font-weight: bold;
		}

		.unit-price {
			text-align: center;
			padding: 8px;
			background-color: var(--white);
			margin: 15px 0;
			font-size: 13px;

			big {
				font-size: 28px;
				font-weight: bold;
				display: block;
				line-height: 1;
				color: var(--grey);
			}
		}

		table {
			width: 100%;

			td {
				padding: 2px 0;
			}
		}

		.right-content {
			padding: 20px 15px;

			.unit-ft-li {
				>div {
					display: inline-block;
				}
			}
		}

		.unit-feature-col {

			i.fi,
			i.fa {
				width: 36px;
				border: 1px solid var(--black);
				height: 36px;
				text-align: center;
				margin-bottom: 5px;
				border-radius: 5px;
				font-size: 20pt;
				line-height: 30px;
				color: var(--black);
				border-radius: 5px;
				display: inline-block;
				background-color: var(--white);
				line-height: 36px;
			}
		}
	}


	.floating-menu {
		background-color: var(--white);
		position: fixed;
		width: 100%;
		z-index: 5;
		padding: 7px 0;
		top: 173px;
		z-index: 2;

		@media (max-width: 992px) and (min-width: 768px) {
			top: 139px;
		}


		&.affix {
			position: fixed;
			width: 100%;
			z-index: 3;
			top: 0;
		}

		.floating-area {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.fl-menu-itm {
				color: var(--white);
				background-color: var(--primary);
			}
		}

		.fl-menu-itm {
			border-color: #6188aa;
			border-radius: 2px;
			font-size: 12px;
			line-height: 1.5;
			padding: 5px 10px;
			display: block;
			width: 100%;
			text-align: center;
			background-color: var(--primary);
			margin-right: 10px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&.btn-secondary {
				background-color: var(--secondary);
				font-size: 20px;
				min-width: 180px;

				@media (max-width: 1199px) {
					display: none;
				}
			}

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				background-color: #4c6e8c;
				border-color: #486986;
			}
		}

	}





	.title-img {

		position: relative;
		width: 100%;
		height: 700px;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;


		.v-left-gallery-btn,
		.v-right-gallery-btn {
			font-size: 160px;
			position: absolute;
			top: 50%;
			margin-top: -57px;
		}

		.v-left-gallery-btn:hover,
		.v-right-gallery-btn:hover {
			color: #585858;
		}

		.v-right-gallery-btn {
			right: 40px;
		}

		.v-left-gallery-btn {
			left: 40px;
		}

	}



	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}

	.legend-cal {
		text-align: center;
		padding: 0;
		margin: 15px 0;

		ul {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: flex-start;
			padding: 0;
		}

		li {
			display: inline-flex;
			align-items: center;
			min-height: 30px;
			padding-right: 15px;
			margin: 5px 0;

			.day {
				width: 30px;
				height: 30px;
				background-color: #e6e6e6;
				display: inline-block;
				margin: 0 10px;
				border: 1px solid #999;

				@media (max-width:992px) {
					width: 15px;
					height: 15px;
				}

				&.day-free {
					background: var(--available);
				}

				&.day-full {
					background: var(--not_available);
				}

				&.day-end {
					background: linear-gradient(135deg, var(--not_available) 50%, var(--available) 50%) !important
				}

				&.day-start {
					background: linear-gradient(135deg, var(--available) 50%, var(--not_available) 50%) !important;
				}
			}
		}
	}



	.main-section {
		position: relative;

		max-width: 1170px;

		h2 {
			margin-top: 40px;
			font-size: 22px;

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: #dfb62d;
			}
		}

		.main-col {
			.new-tag {
				width: 39px;
				height: 39px;
				position: relative;
				left: 0;
				top: 10px;
				background-image: url(RESOURCE/img/neu.png);
				display: inline-block;
			}

			position: static;

			.stars {
				margin-top: 10px;
			}

			.description {
				padding: 15px 15px 5px 15px;
			}

			.backlink {
				margin-top: 20px;
			}


			.intro {
				i {
					font-size: 26px;
				}

				>div {
					text-align: center;
				}
			}

			.properties {
				margin-left: 15px;
				margin-right: 15px;
				@mixin columnlayout 2;

				.prop.bool {
					font-weight: bold;
				}

				.prop.missing {
					text-decoration: line-through;
					font-weight: normal;
					display: none;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}
			}

			.feedbacks {
				margin-top: 30px;

				blockquote {
					div {
						font-family: 'Indie Flower', cursive;
					}

				}
			}


		}

		@media (min-width:768px) {
			.info-col.noaction {
				position: absolute;
				right: 0;
				top: -41px;

				width: 33.33333333%;
				/*
				padding: 3px;
				padding-top: 10px;
				position: -webkit-sticky;
				position: sticky;
				top: 10px;
           */

				.info-col-header {
					height: 40px;
					padding: 1px;
					background-color: var(--primary);
					color: var(--white);
					text-align: center;

					h2 {
						margin-top: 10px;
						font-size: 18px;
					}
				}

				.info-col-main {
					border: 1px solid #888;
					border-bottom: none;
					width: 100%;
				}

				.subprice {
					width: 100%;
					border: 1px solid #888;
					border-top: none;
				}

				.fav-section {
					margin-top: 20px;
					border-bottom: 1px solid #888 !important;
					padding: 15px;
				}

			}
		}

		.info-col {

			max-width: 800px;

			.info-col-main {

				padding: 15px 15px 1px 15px;
			}

			.subprice {

				padding: 1px 15px 15px 15px;

				.filters {}


				.book-btn {
					margin-bottom: 10px;
				}

				.flow-buttons {
					button {
						margin-left: 20px;
					}
				}
			}




		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}


	.subsection {
		width: 100%;

		.unit-map {

			width: 100%;

			#map-mobile {
				height: 500px !important;
				min-height: auto !important;
			}
		}


		.mapnote {
			font-size: 14px;
			padding: 0 0 20px;


		}

		.mapconsent {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			background: rgba(26, 121, 187, 0.15);
			flex-direction: column;
			padding: 15px;

			.doc-box {}

			.mapconsent-button {
				background: var(--primary);
				color: #fff;
				border-radius: 5px;
				padding: 0 15px 0 15px;
				height: 36px;
				display: block;
				line-height: 35px;

				&:hover {
					background: var(--secondary);
				}
			}
		}
	}


	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}




	@media all and (max-width:767px) {

		.title-img {
			height: 350px;

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

		.panorama {
			height: 350px;
		}


		.main-section {

			.main-col {
				.properties {
					@mixin columnlayout 1;
				}
			}
		}

	}


	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		.panorama {
			height: 580px;
		}

		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}


	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}

}

.bottom-book-now {
	/*background: url(RESOURCE/img/bottom-book-now-bg.svg) no-repeat center top;
		background-size: cover;*/
	background: #fff;
	padding: 5px;
	border: 1px solid var(--secondary);
	display: block;

	@media (min-width: 1199px) {
		display: none;
	}

	.container {
		padding: 0;
		width: 100%;
	}

	&:before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		border: 5px solid var(--white);
	}

	a {
		background-color: var(--secondary);
		font-weight: 700;
		padding: 8px 0;
		color: var(--white);
		display: block;
		position: relative;
		z-index: 1;
	}

	@media (max-width:1199px) {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		z-index: 9999;
		display: block !important;
	}
}

.facility-view {

	h2 {
		margin-top: 40px;
		font-size: 22px;
	}

	.reduced-width-container {
		max-width: 970px !important;

		.row {
			padding: 5px;
		}

	}
}



.unit-proposals {
	background-color: #f8f8f8;
}

.unit-feedbacks-v2 {

	margin-top: 30px;

	.ptitle {
		display: inline-block;
	}

	.ratings {

		.rating {
			min-width: 160px;
			float: left;

			.fa {
				color: var(--gold)
			}
		}
	}

	.feedbacks {

		clear: left;
		padding-top: 30px;

		.feedback {
			border-left: 3px solid #716f6a;
			background-color: var(--orange-light);
			padding: 10px;
			font-style: italic;
			margin-bottom: 30px;
			min-height: 110px;

			@media(max-width:992px) {
				min-height: 150px;
			}

			.rating {
				font-size: 12px;
			}

			.guest-text {
				clear: left;
				padding-top: 10px;
				font-size: 14px;
			}

			.author {
				font-size: 13px;
			}

			.comment {
				padding-left: 10px;
				border-left: 3px dotted var(--primary);
				font-size: 14px;
			}

			.agency {
				padding-bottom: 10px;
			}
		}
	}
}


.ergo-modal {
	.modal-body {
		height: 80vh;
		overflow: scroll;

		@media(max-width: 992px) {
			height: 70vh;
		}
	}
}