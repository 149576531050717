.offers-page {
	padding-top: 210px;

	.offer-box {
		position: relative;
		margin-bottom: 30px;
		padding: 30px 15px 15px;

	}

	.offer-blog {
		.offer {
			margin-bottom: 15px;
			background-color: #fff;
			background-color: var(--li-grey);
			width: 100%;
			border-radius: 0px;
			margin-bottom: 40px;

			@media(min-width:992px) {

				display: flex;
				-webkit-box-orient: horizontal;
				-webkit-box-direction: normal;
				flex-direction: row;

			}


			.img-col {
				min-width: 0;
				min-height: 0;
				position: relative;

				@media(min-width:992px) {

					-webkit-box-flex: 1;
					flex: 1 1 0;
				}


				.img-thumbnail {
					padding: 0px;
					background-color: #fff;
					border: none;
					border-radius: 0px;
				}

				.ribbon {
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
					overflow: hidden;
					width: 110px;
					height: 110px;
					text-align: right;
				}

				.ribbon span {
					font-size: 16px;
					font-weight: 500;
					color: #fff;
					text-transform: uppercase;
					text-align: center;
					line-height: 25px;
					transform: rotate(-45deg);
					-webkit-transform: rotate(-45deg);
					width: 160px;
					display: block;
					background: var(--color-secondary);
					/*	box-shadow: 0 3px 10px -5px #000; */
					position: absolute;
					top: 31px;
					left: -39px;

				}

			}

			.info-col {
				position: relative;
				padding: 26px 26px 0 26px;

				@media(min-width:993px) {
					width: 55%;
				}

				h4 {
					font-size: 24px;
					margin-bottom: 20px;
					font-weight: bold;
				}

				.date {
					margin-top: -10px;
				}

				.description {
					margin-top: 40px;
				}

				.button {

					text-align: right;

					@media(max-width:992px) {
						margin-top: 10px;
						margin-bottom: 10px;
					}

					@media(min-width:992px) {
						position: absolute;
						right: 26px;
						bottom: 26px;
					}

					.fa {
						padding-left: 10px;
					}
				}
			}
		}
	}
}