.indexpage {


	.flex-col-3 {
		@media (max-width: 992px) and (min-width: 576px) {
			display: flex;
		}
	}

	.flex-col-2 {
		@media (max-width: 992px) and (min-width: 576px) {
			display: flex;
			flex-wrap: wrap;

			.col-sm-6 {
				width: 50%;
			}
		}
	}

	.img-card {
		margin-bottom: 8px;

		@media (max-width: 992px) and (min-width: 576px) {
			margin-bottom: 15px;
		}

		&.style2 {
			.img-bl {
				@media (max-width: 992px) and (min-width: 576px) {
					height: 220px !important;
				}
			}
		}

		.img-bl {
			height: 240px;
			overflow: hidden;
			position: relative;
			display: block;

			@media (max-width: 992px) and (min-width: 576px) {
				height: 140px;
			}

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}

			&:hover {
				.img-overlay {
					opacity: 1;
					visibility: visible;
				}
			}

			.img-overlay {
				background-color: rgba(0, 0, 0, .8);
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				color: var(--white);
				opacity: 0;
				visibility: hidden;
				transition: all 0.2s;

				.overlay-cont {
					text-align: center;

					i {
						font-size: 40px;
						margin-bottom: 15px;
					}
				}

				span {
					display: block;
					font-size: 16px;
				}
			}
		}

		.card-img-title {
			background-color: var(--primary);
			color: var(--white);
			text-align: center;
			font-weight: 400;
			line-height: 1.0;
			padding: 10px;
			font-size: 16px;
		}

		&.style2 {
			.img-bl {
				height: 170px;

				@media (max-width:992px) {
					height: 220px;
				}
			}


			.card-img-title {
				background-color: var(--secondary);
			}
		}
	}

	section {
		&.bg-brief {
			padding: 30px 0;

			@media (max-width: 767px) {
				word-break: break-word;
				overflow: hidden;
			}

		}

		&.bg-ferien-img {
			background-image: url(RESOURCE/img/bg-ferien-img.jpg);
			background-size: cover;
			background-position: bottom center;

			.ferien-text {
				background-color: rgba(237, 233, 223, .9);
				margin-right: -15px;
				padding: 20px 30px;
				margin-left: -15px;
			}
		}

		.dot-bdr {
			border-right: 5px dotted var(--primary);
			width: 50%;
			height: 100%;

			@media (max-width:767px) {
				width: 100%;
				height: 35px;
				border-right: none;
				border-bottom: 5px dotted var(--primary);
				margin-bottom: 30px;
			}
		}
	}

	.bg-blue-light {
		&.bg-mob-white {
			@media (min-width:993px) {
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
				background-image: url(RESOURCE/img/bg-wellness.jpg);
			}

			@media (max-width: 1520px) and (min-width: 768px) {
				background-size: cover;
			}
		}
	}


	.icon_card {
		i {
			font-size: 40px;
			border-radius: 50%;
		}

		.dtv-start {
			background-color: #EDE9DF !important;
			line-height: 1;
			width: 56px;
			height: 51px;
			display: inline-block;
			line-height: 50px;
		}

		.schirm-start {
			background-color: #D1E6F9 !important;
			line-height: 52px;
			width: 56px;
			height: 51px;
			display: inline-block;
		}
	}

	.ft-icon-beach-view {
		&:before {
			content: "\f1d2";
			font-family: federiconsregular !important;
			font-style: normal;
			font-weight: normal !important;
			vertical-align: middle;
		}
	}
}