@import "common.css";
@import "setup.css";

.vo-search-html {
	min-height: 100%;
	height: 100%;
}

.search-area {
	padding-top: 210px;
}

.vo-search-body {
	min-height: 100%;
	height: 100%;
	@mixin flexlayout column;

	.my-search-token {
		border-radius: 3px !important;
		color: #999;
		background-color: #f4f4f4;
		position: relative;
		padding-left: 28px !important;

		.close {
			padding-left: 2px;
			padding-right: 2px;
			width: 16px;
			height: 16px;
			border: 1px solid #000;
			border-radius: 50%;
			font-size: 13px;
			position: absolute;
			left: 5px;
			top: 4px;
		}
	}

	.search-panel-bx {
		.widget-side {
			background: var(--li-orange);
			padding: 15px;

			.widget-cont-bl {
				background-color: var(--white);
				padding: 5px;
				margin-bottom: 10px;
			}

			.widget-title {
				background-color: var(--secondary);
				padding-left: 5px;
				padding-right: 5px;
				margin-bottom: 0;
				line-height: 1.5;
				color: var(--white);
			}

			.search-bl {
				position: relative;
				padding-left: 39px;

				.box {
					width: 285px;

					label {
						font-size: 14px;
					}

					td {
						.btn {
							width: 38px;
							height: 38px;
							font-size: 26px;
						}
					}
				}

				.form-control {
					color: #495057;
					padding: 9px 7px 8px;
					text-align: left;
					font-size: 13.5px;

					&.select {
						height: 39px;
					}

				}

				i {
					background-color: #E4E2E2;
					border-radius: 2px 0 0 2px;
					border: 1px solid rgba(0, 0, 0, .15);
					font-size: 16px;
					padding: .5rem .75rem;
					position: absolute;
					left: 0;
					top: 0;
					width: 40px;
					height: 39px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}

	.form-control {

		&.style2 {
			padding: 7px 12px;
			font-size: 14px;
		}
	}

	.map-btn {
		a {
			display: inline-block;
			margin-bottom: 15px;
		}
	}

	.result-pane {
		width: 100%;


		.a-color {
			color: #007da7;
		}

		.result-header {
			padding: 0 5px;
			border-bottom: 1px solid rgb(235, 235, 235);
			margin-bottom: 15px;

			.result-title-bar {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 15px;
				flex-wrap: wrap;

				@media (max-width:992px) {
					padding: 15px 0;
				}
			}

			.sorting-trigger {
				cursor: pointer;
			}

			.mysearch {
				margin-bottom: 4px;
			}
		}

		.pagination-panel {
			text-align: center;
		}

	}

	.map-view {
		margin: 0 0 30px 0;

		.result-pane {
			display: none;
		}

		.leaflet-popup-content {
			margin: 0;
			line-height: 1.4;
			width: 290px !important;
			padding: 15px 15px 10px 15px;
		}
	}

	.map-pane {
		width: 100%;
		height: auto;

		@media (max-width: 767px) {
			height: auto;
		}

		#map {

			height: 800px !important;

			@media (max-width: 767px) {
				height: 500px !important;
			}
		}

		.map-main {
			height: 100%;

			&.no-map {
				height: 100%;

				.flex {
					@media (max-width:767px) {
						height: calc(100% - 25px);
					}
				}
			}

			.flex {
				height: 100%;

				@media (max-width:767px) {
					height: calc(100% - 165px);
				}

				>div {
					height: 100%;
				}
			}

			.mapnote {
				padding: 0 0 15px;
			}

			.mapconsent {

				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				background: rgba(26, 121, 187, 0.15);
				flex-direction: column;
				padding: 0 15px;
				height: 100%;

				.mapconsent-button {
					background: var(--primary);
					color: #fff;
					border-radius: 5px;
					padding: 0 15px 0 15px;
					height: 36px;
					display: block;
					line-height: 35px;

					&:hover {
						background: var(--secondary);
					}
				}
			}
		}

		.header {
			display: none;
			padding: 5px;
			background-color: white;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: center;

		&.single-unit {
			border: none;
			margin-bottom: 0;
		}

		a {
			color: inherit;
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;
		}

		.preview-img {
			min-height: unset;
			width: 100%;
			height: 150px;
			overflow: hidden;
			margin-bottom: 10px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.unit-content {
			padding: 0;
		}

		.search-result-properties {
			justify-content: center;
			margin-bottom: 10px;
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

	}


	.results,
	.spinner-panel,
	.more-btn-panel {}

	.more-btn-panel {
		padding-bottom: 40px;
		display: none;

		@media (max-width:992px) {
			display: block;
		}
	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.results {
		.single-unit {
			padding: 8px;
			border: 1px solid #dfdfdf;
			margin-bottom: 20px;

			.unit-content {
				position: relative;

				.unit-title {
					padding-right: 25px;
					color: var(--primary);
					font-weight: normal;
					margin: 0;
				}

				.unit-dt {
					font-size: 14px;
					margin: 0;
					color: var(--primary);
				}

				.fav-icon {
					position: absolute;
					right: 5px;
					font-size: 25px;
					top: 7px;
				}

				.unit-dt-bl {
					display: flex;
					justify-content: inherit;
					background-color: var(--blue-light);
					padding-left: 6px;

					strong {
						font-weight: normal;
						margin: 0;
						display: inline-block;
					}

					span {
						min-width: auto;
						position: relative;
						padding-left: 15px;
						width: auto;

						&:before {
							content: "";
							width: 4px;
							height: 4px;
							border-radius: 50%;
							background-color: var(--primary);
							display: inline-block;
							position: absolute;
							top: 15px;
							left: 4px;
						}

						&:first-child {
							padding-left: 0;

							&:before {
								display: none;
							}
						}
					}
				}
			}

		}

		padding: 0;

		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
		}

		.altheading {
			color: red;
		}

		h4 {
			margin-top: 0;
		}

		.srow {
			width: 100%;
			position: relative;

			.map-marker {
				position: absolute;
				top: 0;
				right: 0;
				display: inline-block;
				width: 50px;
				height: 50px;
				text-align: center;

				.fa-map-marker-alt {
					position: relative;
					top: 12px;
					cursor: pointer;
					font-size: 24px;
					color: #dedede;
				}

				.fa-map-marker-alt:hover,
				.fa-map-marker-alt.selected {
					color: #565a5c;
				}
			}

			.img-content {
				.fav-icon {
					position: absolute;
					cursor: pointer;
					right: 0;
					top: 0;
					color: var(--white);
					margin: 3px;
					font-size: 24px;
					width: 35px;
					text-align: center;
				}
			}

			.img-col {
				overflow: hidden;
				position: relative;

				.new-tag {
					width: 39px;
					height: 39px;
					position: absolute;
					right: 10px;
					top: 10px;
					background-image: url(RESOURCE/img/neu.png);
					z-index: 2;
				}

				.unit-img {

					.full-img {
						width: 100%;
						height: 100%;

					}

				}

				.owl-carousel {
					overflow: hidden;
					height: 100%;

					.owl-stage-outer {
						height: 100%;

						.owl-stage {
							height: 100%;

							.owl-item,
							.item {
								height: 100%;
							}
						}
					}

					.owl-nav {
						button {
							width: 50px;
							height: auto;
							bottom: 0;
							top: 0;
							position: absolute;
							border-radius: 0;
							font-size: 0;
							cursor: pointer;
							transition: all 0.25s ease 0s;
							transform: none;
							color: var(--white);
							font-size: 46px;
							line-height: 8px;
							text-align: center;
						}

						.owl-prev {

							left: 0;
						}

						.owl-next {
							right: 0;

						}
					}


				}


			}

			.info-col {
				position: relative;
				padding: 0 15px 15px;
				background-color: white;
				padding-bottom: 45px;

				.stars {
					position: absolute;
					right: 0;
					bottom: 0;
				}

				.unit-btn {
					position: absolute;
					bottom: 0;
					right: 16px;
				}

				.rent-rate {

					b,
					strong {
						color: var(--primary);
					}

					.offer-price-box {
						.main-price {
							text-decoration: line-through;
							color: var(--black);
						}

						.discount-price {
							color: var(--primary);
						}
					}
				}

				.prices {
					margin-top: 10px;

					.price {

						font-size: 20px;
					}
				}

				.search-result-properties {
					margin-top: 5px;
					margin-bottom: 15px;
				}


			}
		}


	}


	.list-view {
		.map-pane {
			display: none;
		}
	}


	.search-bar {
		margin-bottom: 30px;

		.asd__wrapper--full-screen {
			padding-top: 140px;
		}

		.daterange {
			display: block;
		}

		.btn {
			margin: 2px;
		}

		@media (max-width:768px) {
			.sm-full {
				display: block;

			}
		}
	}

	@media (max-width:767px) {



		.map-view {
			.map-pane {
				@mixin flex;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			top: 0px !important;

			.header {
				display: block;
			}

		}

		.pagination-panel {
			display: none;
		}

	}

	.results {
		.unit-row {
			margin-bottom: 15px;
			padding: 5px;
			padding-bottom: 0;


			.offer-box {
				padding: 15px;
				position: relative;
				display: flex;
				margin: 0;
				align-items: center;
				flex-wrap: wrap;
				background-color: #fff;
				border-top: 1px solid #e5e5e5;
				color: var(--black);


				border-top: 1px solid #ebebeb;

				@media (max-width:767px) {
					display: block
				}

				.offer-left {
					background: var(--primary);
					color: #fff;
					padding: 10px 15px;
					border: 1px dashed #fff;
					font-size: 16px;
					text-transform: uppercase;
					display: flex;
					align-items: center;
					margin-right: 15px;

					@media (max-width:767px) {
						display: block;
						text-align: center;
						margin-bottom: 15px
					}
				}

				ul {
					padding: 0;
					list-style: none;
					margin: 0;

					li {
						margin: 0 0 5px;
						line-height: 14px;
						font-size: 14px
					}
				}
			}

			.alternativesPrice {
				padding: 15px;
				position: relative;
				display: -webkit-box;
				display: flex;
				margin: 0;
				-webkit-box-align: center;
				align-items: center;
				background-color: var(--white);
				flex-wrap: wrap;
				color: var(--black);
				border-top: 1px solid #ebebeb;
			}
		}

	}

	@media (min-width:768px) {


		.results {
			. {
				margin-bottom: 15px;

				.offer-box {
					padding: 15px;
					position: relative;
					display: flex;
					margin: 0;
					align-items: center;
					flex-wrap: wrap;
					background-color: #fff;
					border-top: 1px solid #e5e5e5;
					color: var(--black);


					border-top: 1px solid #ebebeb;

					@media (max-width:767px) {
						display: block
					}

					.offer-left {
						background: var(--primary);
						color: #fff;
						padding: 10px 15px;
						border: 1px dashed #fff;
						font-size: 16px;
						text-transform: uppercase;
						display: flex;
						align-items: center;
						margin-right: 15px;

						@media (max-width:767px) {
							display: block;
							text-align: center;
							margin-bottom: 15px
						}
					}

					ul {
						padding: 0;
						list-style: none;
						margin: 0;

						li {
							margin: 0 0 5px;
							line-height: 14px;
							font-size: 14px
						}
					}
				}

				.alternativesPrice {
					padding: 15px;
					position: relative;
					display: -webkit-box;
					display: flex;
					margin: 0;
					-webkit-box-align: center;
					align-items: center;
					background-color: var(--white);
					flex-wrap: wrap;
					color: var(--black);
					border-top: 1px solid #ebebeb;
				}
			}

			.srow {
				display: flex;
				-webkit-box-orient: horizontal;
				-webkit-box-direction: normal;
				flex-direction: row;

				@media (max-width:992px) {
					display: block;
				}

				.info-col {

					@media (min-width:993px) {
						width: 58%;
						padding-left: 25px;
					}
				}

				.img-col {
					@mixin flex;
					position: relative;

					.img-content {
						@media (min-width:993px) {
							position: absolute;
							width: 100%;
							height: 100%;
						}


						.full-img {
							height: 100%;
							object-fit: cover;

						}
					}

				}

			}
		}



	}


	.owl-carousel {
		z-index: inherit;
	}


}

.search-card-star-container {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	position: absolute;
	right: 0;
	bottom: 0;
	width: 94%;

	.fa-star {
		color: var(--gold);
	}
}