.static-view {
	padding-top: 210px;

	.aside-widget {
		.mapnote {
			margin: 0 0 15px 0;
			font-size: 14px;

		}

		.mapconsent {
			background: rgba(26, 121, 187, .15);
			padding: 15px;
			height: 440px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			text-align: center;

			a {
				background: var(--primary);
				color: #fff;
				border-radius: 5px;
				padding: 0 15px;
				height: 36px;
				display: inline-block;
				line-height: 35px;
				margin: 0 0 15px 0;

				&:hover {
					background: var(--secondary);
				}
			}
		}
	}


	.img-card-bl {
		height: 240px;
		overflow: hidden;
		position: relative;
		display: block;
		margin-bottom: 15px;

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.btn-blocks {
		.btn {
			margin-bottom: 5px;
		}
	}

	.webcam-bl {
		position: relative;
		padding-top: 70%;

		iframe {
			width: 100%;
			position: absolute;
			left: 0;
			height: 100%;
			top: 0;
		}
	}

	.inner-banner {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		height: 240px;
		background-color: #E6E6E6;
		border-bottom: 1px solid rgba(102, 102, 102, 0.2);
		position: relative;
		top: -24px;

		&.wichtige {
			background-image: url(RESOURCE/img/bannre-wichtige.jpg);
		}

		&.info {
			background-image: url(RESOURCE/img/banner-info.jpg);
		}
	}

	.bg-light-orange {
		&.pt-15 {
			@media (max-width:992px) {
				margin: 15px 15px 0;
			}
		}
	}

	.terms-bl {
		padding: 20px;
		background-color: #DFDBD5;

		a {
			font-size: 16px;
			position: relative;
			top: 0px;
		}

		label {
			margin-left: 25px;

			&:before {
				top: 4px;
			}

			&:after {
				top: 3px;
			}
		}

	}

	.pdf-btn {
		img {
			display: inline-block;
		}
	}

	.contact-map {
		#map {
			min-height: unset !important;
			height: 430px !important;
			margin-bottom: 0;
		}
	}
}