:root {
	--introImg: url(RESOURCE/img/bg-banner-home-new.jpg);
	--introMobileImg: url(RESOURCE/img/bg-banner-home-new.Xc_770_770.jpg);
	--navbarbreak: 768px;
	--primary: #6188AA;
	--secondary: #FF7719;
	--infocolor: #D1E6F9;
	--white: #fff;
	--li-grey: #f2f2f2;
	--grey: #5E7077;
	--blue-light: #D1E6F9;
	--orange-light: #EDE9DF;
	--black: #000;
	--li-orange: #EDE9DF;
	--gold: #FFD700;

	/*Calendar colors*/
	--not_available: #F97E4D;
	--disabled: #CCC;
	--available: #D1E6F9;
	--selected: #032c55;
	--selection_range: #6188AA;
	--selected_border: #fff;
	--text_color: #333;
}