@import "setup.css";

.top-header {
	border-bottom: 5px solid var(--white);
	background-color: var(--primary);
	padding-top: 15px;
	width: 100%;

	.navbar-top {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@media (max-width:767px) {
			display: block;
			text-align: center;
		}

		.form-control {
			padding: 6px 12px;
		}

		.btn {
			padding: 9px 12px;
			font-size: 14px;
		}

		.nav-right {
			text-align: center;
		}
	}

	.logo-text {
		font-size: 52px;
		color: var(--white);
		font-weight: bold;
		font-family: verdana, sans-serif;
		position: relative;
		top: -6px;

		@media (max-width:992px) {
			font-size: 38px;
			line-height: 1;

			text-align: center;

		}
	}

	.tel-text {
		font-size: 31px;
		color: var(--white);

		i {
			font-size: 24px;
		}
	}
}

.navbar-area {
	background-color: var(--secondary);
	border-top: none;

	@media (max-width:992px) {
		padding-bottom: 8px;
		border-top: none;
	}



	.search {
		display: none !important;
	}

	.logo-text {
		@media (max-width:992px) {
			position: inherit;
			top: 0;
		}
	}


	.top-header {
		padding-top: 0;
		transition: all 0.2s ease-in-out;
		overflow: hidden;
		border-bottom: 5px solid var(--white) !important;



		.tel-text {
			margin-top: 12px;
			display: inline-block;

			@media (max-width:992px) {
				margin-top: 0;
			}
		}
	}

	&.affix-top {
		.top-header {
			height: 0;
		}
	}

	&.affix {
		position: sticky !important;
		top: 0;
		z-index: 5;
		/*border-top: 5px solid var(--white);*/

		.top-header {
			display: flex;
			align-items: center;

			@media (min-width:768px) {
				height: 70px;
			}

			@media (max-width:767px) {
				height: 82px;
			}
		}
	}
}

.inner-header {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 5;

	.navbar-area {
		/*border-top: 5px solid var(--white);*/

		.top-header {
			padding-top: 15px;
		}

		&.affix {
			.top-header {
				height: auto;
			}
		}

		&.affix-top {
			.top-header {
				height: auto;
			}
		}
	}

	.vo-nav-bar .main-menu>ul>li>a {
		margin-top: 0;
	}
}

.vo-nav-bar {
	.icon-toggle {
		float: right;
		display: block;
		top: 5px;
		position: relative;
		right: 5px;
		font-size: 22px !important;
		padding: 3px 12px;
		background-color: transparent;
		color: var(--white);
		display: none;

		@media (max-width:992px) {
			display: block;
			width: 100%;
			float: none;
			text-align: right;
		}
	}

	.icon-close {
		display: none;
	}

	&.opened {
		.main-menu {
			right: 0;

		}

		.icon-close {
			position: absolute;
			left: 0;
			background: var(--secondary);
			font-size: 24px;
			margin-left: -45px;
			color: var(--white);
			padding: 10px 18px;
			border: none;
			box-shadow: none;
			display: block;
			font-weight: bold;
		}
	}

	.main-menu {
		@media (max-width:992px) {
			position: fixed;
			right: -100%;
			top: 0;
			height: 100vh;
			background-color: var(--primary);
			z-index: 50;
			width: 70%;
			transition: all 0.3s ease;
			padding: 0;
		}

		.navbar {
			min-height: inherit;
			margin: 0;

			@media (max-width:992px) {
				position: inherit;
			}
		}

		.navbar-toggler {
			background: transparent;
			font-size: 24px;
			color: var(--white);
			padding: 0;
			border: none;
			box-shadow: none;
			display: none;

			@media (max-width:992px) {
				display: inline-block;
				position: absolute;
				left: 20px;
				top: 24px;

			}
		}



		>ul {
			margin: 0;
			padding: 0px;
			display: flex;
			justify-content: center;
			float: none;
			flex-wrap: wrap;

			@media (max-width:992px) {
				height: 100vh;
				overflow: scroll;
				display: block;
			}

			@media (min-width:993px) {
				margin: 5px 0 0;
			}

			/*&.two-col-drop {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				width: 440px;

				li {
					@media (min-width:993px) {
						width: 50% !important;
					}
				}
			}*/

			>li {
				list-style: none;
				padding: 0px;
				margin: 0px 5px;

				.icon-caret {
					content: "";
					border-top: 5px solid var(--white);
					border-right: 5px solid transparent;
					border-left: 5px solid transparent;

					position: relative;
					top: 15px;
					right: -6px;
					line-height: 1;

					@media (max-width:992px) {
						border-top: 5px solid rgba(255, 255, 255, .7);
						top: 12px;
					}
				}

				@media (max-width:1240px) {
					margin: 0 2px;
				}

				@media (max-width:992px) {
					width: 100%;
					border-bottom: 1px solid rgba(255, 255, 255, 1);

					&.open {
						.dropdown-menu {
							display: block;

						}

						background-color:#a3bace;
					}
				}

				>a {
					color: var(--white);
					font-size: 18px;
					font-weight: normal;
					text-transform: none;
					padding: 8px 11px;
					display: inline-block;
					text-transform: uppercase;
					margin-top: 3px;
					line-height: 27px;

					@media (max-width:992px) {
						padding: 15px 20px;
						margin: 0;
						font-weight: normal;
						font-size: 13px;
						color: rgba(255, 255, 255, 0.7);
					}
				}

				&:hover {
					@media (min-width:993px) {
						background-color: var(--white);

						.icon-caret {
							border-top: 5px solid var(--secondary);
						}

						a {
							color: var(--secondary);
						}

						.dropdown-menu {
							display: block;
						}


						.dropdown-menu {
							opacity: 1;
							visibility: visible;
							top: 100% !important;
						}
					}


				}

				.dropdown-menu {

					padding: 8px;
					border-radius: 0;
					left: auto;
					border: 1px solid var(--li-grey);
					border-bottom: 2px solid var(--primary);
					min-width: 250px;
					display: inherit;
					background: var(--white);
					position: absolute;
					z-index: 5;
					transition: all 0.3s ease;
					visibility: hidden;
					top: 140% !important;
					opacity: 0;

					@media (max-width:992px) {
						position: relative;
						background: #82a1bc;
						width: 100%;
						min-width: auto;
						padding: 0;
						display: none;
						border: none;
						visibility: visible;
						opacity: 1;
						top: 0 !important;
					}

					ul {
						padding: 0;
					}

					li {
						padding: 8px 15px !important;

						border-bottom: 1px solid #E6E6E6 !important;
						color: #666;
						list-style: none;

						&:last-child {
							border-bottom: none;
						}
					}

					a {
						font-size: 12px;
						display: block;
						padding: 0;
						color: #464646;
						font-size: 12px;

						@media (max-width:992px) {
							color: #fff;
						}

						&:before {
							content: "\f054";
							font-family: "FontAwesome";
							font-weight: 400;
							font-size: 10px;
							margin-right: 8px;
							color: rgba(102, 102, 102, 0.6);

							@media (max-width:992px) {
								color: var(--white);
							}
						}
					}
				}
			}

		}

	}


}