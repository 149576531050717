@import "setup.css";

.indexpage {


	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.single-unit {
		.unit-title {
			min-height: 50px;
		}
	}

	.single-unit .search-result-properties {
		min-height: 64px;
	}

	.iheader {
		min-height: 385px;
		background: var(--introImg) no-repeat center;
		-webkit-background-size: cover;
		background-size: cover;

		position: relative;
		padding-top: 120px;

		@media (max-width:992px) {
			padding-top: 70px;
		}

		@media (max-width:767px) {
			min-height: 300px;
			padding-top: 40px;
		}

		.intro {
			text-align: center;

			.hero {
				text-align: center;
				color: #fff;
				background: none;

				margin-bottom: 10px;
				padding-bottom: 0px;

				h1 {

					font-family: 'Dancing Script', cursive;
					font-size: 80px;
					text-align: right;

					@media (max-width:992px) {
						font-size: 60px;
					}

					@media (max-width:767px) {
						display: none;
					}

				}


			}

		}


	}



	.searchbar {
		margin-top: 20px;
		background-color: rgba(255, 255, 255, 0.8);
		padding: 10px 12px;

		.row {
			margin: 0 2px;

			.col-md-3,
			.col-md-4,
			.col-md-2 {
				padding: 0 2px;
			}
		}

		@media (max-width:992px) {
			width: 90%;
		}

		.searchbox {

			.search-bl {
				position: relative;
				padding-left: 39px;

				.form-control {
					font-size: 15px;
					color: #495057;
					padding: 9px 7px 6px;
					min-height: 39px;
					text-align: left;
				}

				i {
					background-color: #E4E2E2;
					border-radius: 2px 0 0 2px;
					border: 1px solid rgba(0, 0, 0, .15);
					font-size: 16px;
					padding: .5rem .75rem;
					position: absolute;
					left: 0;
					top: 0;
					width: 40px;
					height: 39px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			input,
			.search-btn,
			select {
				border-radius: 0;
			}

			.search-btn {
				width: 100%;
				/*text-align: left;*/

				@media (max-width:992px) {
					width: auto;
					float: right;
				}

				i {
					position: relative;
					transform: rotate(90deg);
				}
			}

			.search-popup-trigger {
				cursor: pointer;
			}

			.form-control:disabled,
			.form-control[readonly] {
				background-color: white;
			}
		}

		@media (min-width:768px) {
			max-width: 650px;
		}

		@media (min-width:993px) {
			max-width: 950px;
		}

	}

	.contact-section {
		background-color: #fff !important;
	}






	@media (max-width:767px) {

		.iheader {
			background: var(--introMobileImg) no-repeat center;
			-webkit-background-size: cover;
			background-size: cover;

			.intro {
				.hero {
					h2 {
						font-size: 18px;
					}
				}
			}


		}
	}






}